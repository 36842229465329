import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { VacanciesPage } from './pages/VacanciesPage/VacanciesPage';
import { ScrollToTop } from './components/ScrollToTop';
import { VacancyPage } from './pages/VacancyPage/VacancyPage';
import { ContactsPage } from './pages/ContactsPage/ContactsPage';
import { CookiePolicy } from './pages/StaticPages/CookiePolicy';
import { PrivacyPolicy } from './pages/StaticPages/PrivacyPolicy';
import { TermsAndConditions } from './pages/StaticPages/TermsAndConditions';
import { SurveyPage } from './pages/SurveyPage/SurveyPage';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/careers" element={<VacanciesPage />} />
          <Route path="/careers/:id" element={<VacancyPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/get-in-touch" element={<SurveyPage />} />

          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
