import { observer } from 'mobx-react';
import cn from 'classnames';
import { useStore } from '../../stores';
import { Link } from 'react-router-dom';
import { SurveyForm } from '../../components/SurveyForm/SurveyForm';
import { useEffect } from 'react';

export const SurveyPage = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const {
    activeSurveyItem,
    onNextButtonClick,
    onBackButtonClick,
    activeSurveyIndex,
    surveyItems,
    isItemValid,
    resetSurvey,
    isSuccess,
    init,
  } = surveyStore;

  useEffect(() => {
    init();
    return () => {
      resetSurvey();
    };
  }, [resetSurvey, init]);
  return (
    <div className="SurveyPage">
      {isSuccess ? (
        <div className="SurveyPage__success">
          <div className="Icon success" />
          <div className="SurveyPage__success__title">Thank you!</div>
          <div className="SurveyPage__success__description">
            Appreciate your interest!
            <br />
            We will contact you shortly to discuss the details and schedule a meeting for a product demonstration in
            Zoom.
          </div>
          <Link to="/">
            <button className="Button PrimaryButton RoundedButton">Back to home</button>
          </Link>
          <div className="Logo SurveyPage__logo" />
        </div>
      ) : (
        <>
          <div className="SurveyPage__left">
            <div className={cn('Icon SurveyPage__surveyIcon', activeSurveyItem?.icon)} />

            {/*<SurveyProgress />*/}
            <div className="SurveyPage__title">{activeSurveyItem?.title}</div>
            <div className="Header__logo SurveyPage__logo" />
          </div>
          <div className="SurveyPage__right">
            <Link to="/">
              <div className="SurveyPage__backButton">
                <div className="Icon arrow-left" />
                Back to samenstak.com
              </div>
            </Link>
            <SurveyForm />
            <div className="SurveyPage__buttons">
              {activeSurveyIndex > 0 ? (
                <button onClick={onBackButtonClick} className="Button SecondaryButton RoundedButton-2">
                  Back
                </button>
              ) : null}
              <button
                onClick={onNextButtonClick}
                className={cn('Button PrimaryButton RoundedButton-2', { disabled: !isItemValid })}
              >
                {activeSurveyIndex === surveyItems.length - 1 && activeSurveyIndex !== 0 ? 'Submit' : 'Next'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
