import { observer } from 'mobx-react';
import { useStore } from '../../stores';
import cn from 'classnames';

export const SurveyForm = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const { activeSurveyItem } = surveyStore;
  if (!activeSurveyItem) return null;
  const { additionalTitle, type } = activeSurveyItem;

  return (
    <div className="SurveyForm">
      <div className="SurveyForm__title">{additionalTitle}</div>
      <div className="SurveyForm__form">{type === 'select' ? <SurveyFormSelect /> : <SurveyInputs />}</div>
    </div>
  );
});

const SurveyFormSelect = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const { activeSurveyItem, answers, changeSurveySelect } = surveyStore;
  const { selectItems, title } = activeSurveyItem;
  const selectedItem = answers[title] ? selectItems?.find(item => item.value === answers[title]) : undefined;

  return (
    <div>
      <div className="SurveyFormSelect">
        {selectItems?.map(item => (
          <div
            onClick={() => changeSurveySelect(title, item.value)}
            className={cn('SurveyFormSelect__item', { active: answers[title] && answers[title] === item.value })}
          >
            <div className="SurveyFormSelect__item__radio" />
            <div className="SurveyFormSelect__item__label">{item.label}</div>
          </div>
        ))}
        {selectedItem?.withInput ? (
          <>
            {selectedItem.inputType === 'input' ? (
              <input className="SurveyInput" placeholder={selectedItem.inputPlaceholder} />
            ) : (
              <textarea className="SurveyInput" rows={4} placeholder={selectedItem.inputPlaceholder} />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
});

const SurveyInputs = observer(() => {
  const store = useStore();
  const { surveyStore } = store;
  const { activeSurveyItem, answers, changeSurveyInput } = surveyStore;
  const { inputs } = activeSurveyItem;

  return (
    <div>
      <div className="SurveyInputs">
        {inputs?.map(input =>
          input.type === 'textarea' ? (
            <textarea
              value={answers[input.label] ?? ''}
              onChange={e => changeSurveyInput(input.label, e.target.value)}
              className="SurveyInput"
              rows={4}
              placeholder={input.label}
            />
          ) : (
            <input
              className="SurveyInput"
              placeholder={input.label}
              type={input.type}
              value={answers[input.label] ?? ''}
              onChange={e => changeSurveyInput(input.label, e.target.value)}
            />
          ),
        )}
      </div>
    </div>
  );
});
