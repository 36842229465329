import { makeAutoObservable } from 'mobx';
import surveyMain from '../data/survey/survey-main.json';
import survey1 from '../data/survey/survey-1.json';
import survey2 from '../data/survey/survey-2.json';
import survey3 from '../data/survey/survey-3.json';

import { SurveyItem } from '../types/Survey';

export class SurveyStore {
  surveyItems: SurveyItem[] = [];
  activeSurveyIndex: number = 0;
  answers: { [key: string]: string } = {};
  isSuccess: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.surveyItems = surveyMain as SurveyItem[];
    this.prefillAnswers();
  };

  prefillAnswers = () => {
    this.surveyItems.forEach(item => {
      if (item.type === 'fields') {
        item.inputs?.forEach(input => {
          this.answers[input.label] = '';
        });
      }
      if (item.type === 'select') {
        this.answers[item.title] = item.selectItems && item.selectItems[0] ? item.selectItems[0].value : '';
      }
    });
  };

  get activeSurveyItem() {
    return this.surveyItems[this.activeSurveyIndex] ?? this.surveyItems[0];
  }

  get isItemValid() {
    if (this.activeSurveyItem) {
      if (this.activeSurveyItem.type === 'fields') {
        return this.activeSurveyItem.inputs?.every(input => !!this.answers[input.label]);
      } else {
        return !!this.answers[this.activeSurveyItem.title];
      }
    }
    return false;
  }

  changeActiveItem = (index: number) => {
    this.activeSurveyIndex = index;
  };

  changeSurveySelect = (key: string, value: string) => {
    this.answers[key] = value;
  };

  changeSurveyInput = (key: string, value?: string) => {
    this.answers[key] = value ?? '';
  };

  onNextButtonClick = () => {
    if (this.isItemValid) {
      if (this.surveyItems?.length - 1 > this.activeSurveyIndex || this.activeSurveyIndex === 0) {
        if (this.activeSurveyIndex === 0 && this.answers[this.activeSurveyItem?.title]) {
          switch (this.answers[this.activeSurveyItem?.title]) {
            case 'Smoli software solution':
              this.surveyItems = [...this.surveyItems, ...(survey2 as SurveyItem[])];
              break;
            case 'DeployPlace software':
              this.surveyItems = [...this.surveyItems, ...(survey3 as SurveyItem[])];
              break;
            default:
              this.surveyItems = [...this.surveyItems, ...(survey1 as SurveyItem[])];
              break;
          }
          this.prefillAnswers();
        }
        this.activeSurveyIndex = this.activeSurveyIndex + 1;
      } else {
        this.isSuccess = true;
      }
    }
  };

  onBackButtonClick = () => {
    if (this.activeSurveyIndex - 1 === 0) {
      this.surveyItems = surveyMain as SurveyItem[];
      this.answers = {};
      this.prefillAnswers();
    }
    this.activeSurveyIndex = this.activeSurveyIndex - 1;
  };

  resetSurvey = () => {
    this.activeSurveyIndex = 0;
    this.answers = {};
    this.isSuccess = false;
  };
}
