import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Vacancies } from '../../components/Vacancies/Vacancies';
import vacancies from '../../data/vacancies.json';
import { PageTitleBlock } from '../../components/PageTitleBlock/PageTitleBlock';

export const VacanciesPage = () => {
  return (
    <div className="VacanciesPage PageContainer">
      <Header />
      <PageTitleBlock title="Careers" />
      <h2 className="VacanciesPage__vacanciesTitle">{vacancies?.length} vacancies available</h2>
      <Vacancies vacancies={vacancies} />
      <Footer />
    </div>
  );
};
