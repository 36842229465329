import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { PageTitleBlock } from '../../components/PageTitleBlock/PageTitleBlock';

export const ContactsPage = () => {
  return (
    <div className="ContactsPage PageContainer">
      <Header />
      <PageTitleBlock title="Contacts" />
      <div className="ContactsPage__cards">
        <div className="ContactsPage__card">
          <div className="ContactsPage__card__title">Address</div>
          <div className="ContactsPage__card__value">
            Georgia, Tbilisi city, Vake district, Elizbar Mindeli Street, N 12
          </div>
        </div>
        <div className="ContactsPage__card">
          <div className="ContactsPage__card__title">Enail</div>
          <div className="ContactsPage__card__value">contact@samenstak.com</div>
        </div>
        <div className="ContactsPage__card">
          <div className="ContactsPage__card__title">Social Media</div>
          <div className="ContactsPage__card__social">
            <a href="https://www.linkedin.com/company/samenstak" target="blank">
              <div className="ContactsPage__card__social__item">
                <div className="Icon linkedin" />
                LinkedIn
              </div>
            </a>
            <a href="https://www.crunchbase.com/organization/samenstak-llc" target="blank">
              <div className="ContactsPage__card__social__item">
                <div className="Icon crunchbase" />
                Crunchbase
              </div>
            </a>
            <div className="ContactsPage__card__social__item">
              <div className="Icon twitter" />
              Twitter
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
