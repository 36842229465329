import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { PageTitleBlock } from '../../components/PageTitleBlock/PageTitleBlock';
import vacancies from '../../data/vacancies.json';
import { useEffect, useState } from 'react';

export const VacancyPage = () => {
  const params = useParams();
  const [description, setDescription] = useState<string>('');
  const vacancy = vacancies.find(_ => _.id === Number(params.id));
  useEffect(() => {
    try {
      const path = require(`../../data/vacancies/${params.id}.md`);
      fetch(path)
        .then(response => response.text())
        .then(text => {
          setDescription(text);
        });
    } catch (e) {}
  }, [params.id]);
  return (
    <div className="VacancyPage PageContainer">
      <Header />
      <PageTitleBlock
        title={vacancy?.title ?? ''}
        additionalComponent={
          <div className="VacancyPage__titleAdditional">
            <div className="VacancyPage__titleAdditional__info">
              <div className="VacancyItem__info__item">
                <div className="VacancyItem__info__item__timeIcon" />
                {vacancy?.employment}
              </div>
              <div className="VacancyItem__info__item">
                <div className="VacancyItem__info__item__remoteIcon" />
                Remote
              </div>
            </div>
            <a href="mailto:contact@samenstak.com">
              <button className="Button PrimaryButton small VacancyPage__applyButton">Apply</button>
            </a>
          </div>
        }
      />
      <div className="VacancyPage__descriptionBlock">
        <h2 className="VacancyPage__descriptionTitle">Description</h2>
        <div className="VacancyPage__description">
          <ReactMarkdown children={description} />
        </div>
        <a href="mailto:contact@samenstak.com">
          <button className="Button PrimaryButton small VacancyPage__applyButton">Apply</button>
        </a>
      </div>

      <Footer />
    </div>
  );
};
