import { Link } from 'react-router-dom';
import { IVacancy } from '../../types/vacancies';

interface IVacanciesProps {
  vacancies: IVacancy[];
}

export const Vacancies = ({ vacancies }: IVacanciesProps) => {
  return (
    <div className="Vacancies">
      <div className="Vacancies__list">
        {vacancies.map(vacancy => (
          <VacancyItem vacancy={vacancy} />
        ))}
      </div>
    </div>
  );
};

const VacancyItem = ({ vacancy }: { vacancy: IVacancy }) => {
  return (
    <Link to={`/careers/${vacancy.id}`} className="VacancyItem">
      <div>
        <div className="VacancyItem__label">{vacancy.label}</div>
        <div className="VacancyItem__title">{vacancy.title}</div>
      </div>
      <div className="VacancyItem__info">
        <div className="VacancyItem__info__item">
          <div className="VacancyItem__info__item__timeIcon" />
          {vacancy.employment}
        </div>
        <div className="VacancyItem__info__item">
          <div className="VacancyItem__info__item__remoteIcon" />
          Remote
        </div>
      </div>
    </Link>
  );
};
