import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useComponentVisible from '../../hooks/useComponentVisible';

export const Header = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const location = useLocation();

  const toogleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  const closeMenu = () => {
    setIsComponentVisible(false);
  };
  return (
    <header className={cn('Header', { menuIsOpen: isComponentVisible })}>
      <div className="Header__menuBackground" />
      <div className="Header__inner" ref={ref}>
        <div className="Header__menuButton" onClick={toogleMenu}>
          <div className="Header__menuIcon" />
        </div>
        <div className="Header__menu">
          <a onClick={closeMenu} href={'/#services'}>
            Services
          </a>
          {/*<a onClick={closeMenu} href={'/#products'}>
            Products
          </a> */}
          <a onClick={closeMenu} href={'/#about-us'}>
            About us
          </a>
          <Link className={location.pathname === '/careers' ? 'active' : ''} onClick={closeMenu} to={'/careers'}>
            Career
          </Link>
          <Link className={location.pathname === '/contacts' ? 'active' : ''} onClick={closeMenu} to={'/contacts'}>
            Contacts
          </Link>
        </div>
        <div className="Header__logoContainer">
          <Link to="/">
            <div className="Header__logo" />
          </Link>
        </div>
        <Link to="/get-in-touch" className="Header__getButton">
          Get in touch
        </Link>
      </div>
    </header>
  );
};
