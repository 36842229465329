import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__column contacts">
        <div className="Footer__column__title">Contacts</div>
        <div className="Footer__contacts__item">
          <span className="Footer__contacts__item__name">Address: </span>
          <span className="Footer__contacts__item__value">
            Georgia, Tbilisi city, Vake district, Elizbar Mindeli Street, N 12
          </span>
        </div>
        <div className="Footer__contacts__item">
          <span className="Footer__contacts__item__name">Email: </span>
          <span className="Footer__contacts__item__email">
            <a href="mailto:contact@samenstak.com">contact@samenstak.com</a>
          </span>
        </div>
      </div>
      <div className="Footer__column">
        <div className="Footer__column__title">Legal</div>
        <Link to="/terms-and-conditions">Terms</Link>
        <Link to="/privacy-policy">Privacy</Link>
        <Link to="/cookie-policy">Cookies</Link>
      </div>
      <div className="Footer__column">
        <div className="Footer__column__title">Company</div>
        <Link to="/careers">Careers</Link>
        <Link to="/contacts">Contacts</Link>
      </div>
      <div className="Footer__column">
        <div className="Footer__column__title">Social media</div>
        <a href="https://www.linkedin.com/company/samenstak/" target="blank">
          <div className="Icon linkedin small" />
          LinkedIn
        </a>
        <a href="https://www.crunchbase.com/organization/samenstak-llc" target="blank">
          <div className="Icon crunchbase small" />
          Crunchbase
        </a>
        <a href="/">
          <div className="Icon twitter small" />
          Twitter
        </a>
      </div>
      <div className="Footer__column company">
        <div className="Footer__column__title company">© 2023. Samenstak LLC. All rights reserved.</div>
      </div>
    </footer>
  );
};
