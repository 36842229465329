import { Link } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Vacancies } from '../../components/Vacancies/Vacancies';
import services from '../../data/services.json';
import vacancies from '../../data/vacancies.json';

export const MainPage = () => {
  return (
    <div className="MainPage">
      <Header />
      <div className="MainPage__topBlock MainPage__block">
        <div className="MainPage__topBlock__content">
          <h1 className="MainPage__topBlock__title">
            Transform Your Ideas into Reality with Professional Software Development
          </h1>
          <p className="MainPage__topBlock__description">
            From concept to implementation, we deliver exceptional software development services
          </p>
          <Link to="/get-in-touch">
            <button className="Button PrimaryButton RoundedButton MainPage__topBlock__getButton">Get in touch</button>
          </Link>
        </div>
      </div>
      <div className="MainPage__services MainPage__block">
        <div className="hidden-anchor" id="services" />
        <h2 className="MainPage__title">Services</h2>
        <div className="MainPage__services__list">
          {services.map(service => (
            <div className="MainPage__services__item">
              <div className="MainPage__services__item__title">{service.title}</div>
              <div className="MainPage__services__item__description">{service.description}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="MainPage__products MainPage__block">
        <div className="hidden-anchor" id="products" />
        <h2 className="MainPage__title">Our latest product</h2>
        <div className="MainPage__products__block">
          <div className="MainPage__products__block__textContent">
            <div className="MainPage__products__title">Smoli — Time Tracker App</div>
            <div className="MainPage__products__descriprion">
              Introducing Smoli, a powerful time tracking solution designed to enhance your productivity.
              <br /> With its intuitive web interface, you can easily view reports and analyze your time usage.
              Additionally, smoli provides desktop applications for Windows, macOS, and Linux, allowing you to record
              your screen and effortlessly capture your work activities. Stay organized, track your time with precision,
              and boost your productivity with smoli.
            </div>
          </div>
          <div className="MainPage__products__block__imagesBlock">
            <div className="MainPage__products__block__images" />
          </div>
        </div>
      </div>
      <div className="hidden-anchor" id="about-us" />
      <div className="MainPage__aboutUs MainPage__block">
        <div className="MainPage__aboutUs__block">
          <div className="MainPage__aboutUs__decoration" />
          <div className="MainPage__aboutUs__title">About us</div>
          <div className="MainPage__aboutUs__description">
            At <b>Samenstak</b>, we are passionate about crafting exceptional software solutions that empower businesses
            and drive innovation. With our expertise in software development, we help companies across industries
            achieve their goals and unlock their full potential.
          </div>
        </div>
        <div className="MainPage__approach">
          <div className="MainPage__approach__icon" />
          <div className="MainPage__approach__title">Our approach</div>
          <div className="MainPage__approach__description">
            We believe in a collaborative approach to software development, working closely with our clients to
            understand their unique needs and challenges. By combining our technical prowess with a deep understanding
            of business processes, we deliver tailored software solutions that align perfectly with our clients'
            objectives.
          </div>
        </div>
      </div>
      <div className="hidden-anchor" id="career" />
      <div className="MainPage__vacancies MainPage__block">
        <div className="MainPage__vacancies__titleBlock">
          <div className="MainPage__title">We are hiring!</div>
          <Link to="/careers">
            <div className="MainPage__vacancies__viewAll">View all</div>
          </Link>
        </div>
        <Vacancies vacancies={vacancies.slice(0, 4)} />
        <Link to="/careers">
          <div className="MainPage__vacancies__viewAll mobile">View all</div>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
