import { ReactNode } from 'react';
import { useNavigate } from 'react-router';

interface IPageTitleBlockProps {
  title: string;
  additionalComponent?: ReactNode;
}

export const PageTitleBlock = ({ title, additionalComponent }: IPageTitleBlockProps) => {
  const navigate = useNavigate();

  return (
    <div className="PageTitleBlock">
      <button className="BackButton" onClick={() => navigate(-1)}>
        Back
      </button>
      <h1 className="PageTitle">{title}</h1>
      {additionalComponent}
    </div>
  );
};
