import { makeAutoObservable } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { SurveyStore } from './SurveyStore';

export class AppState {
  surveyStore = new SurveyStore();

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new AppState();

export function useStore(): AppState {
  return React.useContext(MobXProviderContext).store;
}
